import { useEffect, useState } from "react";

const defaultConfig = {
  postCode: "",
  coordinates: "",
  distance: "10",
  category: null,
  currentTab: "",
  settings: {
    pushNotifications: false,
  },
  shortListed: [],
  sse: null,
};

const usePersistObject = (key = "config", defaultValue = defaultConfig) => {
  const [value, setValue] = useState(() => {
    try {
      const saved = localStorage.getItem(key);

      if (saved !== null) {
        return JSON.parse(saved);
      }
      return defaultValue;
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const rawValue = JSON.stringify(value);
      localStorage.setItem(key, rawValue);
    }, 300); // Delay for 300ms

    return () => clearTimeout(timeoutId);
  }, [key, value]);

  return [value, setValue];
};

export default usePersistObject;
