import React, { useEffect, useState } from "react";

import { useMediaQuery, Container, Tabs, Tab, Grid, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import { useNavigate } from "react-router-dom";
import { useGetAllTradersQuery } from "../../features/admin/adminApiSlice";

import { styled } from "@mui/material/styles";

import useAuth from "../../hooks/useAuth";
import TraderStatus from "./TraderStatus";
import { useDispatch, useSelector } from "react-redux";
import { getAllTraders, setAllTraders } from "../../app/state";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

// ToDo: Get from traders status
const tabsGroup = [
  { value: "pending", label: "Pending" },
  { value: "review", label: "Review" },
  { value: "active", label: "Active" },
  { value: "rejected", label: "Rejected" },
  { value: "disabled", label: "Disabled" },
  { value: "all", label: "All" },
];

const TradersStatus = () => {
  const dispatch = useDispatch();
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, refetch } = useGetAllTradersQuery();
  // const { traders, isLoading, isError, error, refreshTradersList } =
  //   useTradersData();

  // const { data, isLoading, isError, error, refetch } = useGetAllTradersQuery();
  // const { code, status, message, result: traders } = data || {};
  const allTraders = useSelector(getAllTraders);

  //const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("all"); // change to pending
  const [tabs, setTabs] = useState(tabsGroup);
  const navigate = useNavigate();

  const { user_name, isManager, isAdmin } = useAuth();
  const theme = createTheme();

  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    setTabs(tabsGroup);
    setCurrentTab("all");
  }, []);
  useEffect(() => {
    const callRefetch = async () => {
      const { data } = await refetch();
      const { code, status, message, result } = data || {};

      dispatch(setAllTraders(result));
      setShouldFetch(false);
    };
    if (shouldFetch) {
      callRefetch();
    }
  }, [dispatch, refetch, shouldFetch]);
  useEffect(() => {
    if (data) {
      const { code, status, message, result } = data || {};
      dispatch(setAllTraders(result));
      setShouldFetch(false);
    }
  }, [data, dispatch]);

  const filteredTraders =
    currentTab === "all"
      ? allTraders
      : allTraders?.filter((tr) => tr.status === currentTab);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        {tabs && (
          <Box
            sx={{
              paddingTop: "20px",
              paddingLeft: "20px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid item xs={6}>
              <TabsWrapper
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map((tab) => {
                  // let disabledFlag = false;
                  // if (tab.value === "registration" || isError) {
                  //   disabledFlag = true;
                  // }
                  return (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      // disabled={disabledFlag}
                    />
                  );
                })}
              </TabsWrapper>
            </Grid>

            <RefreshTwoToneIcon
              onClick={() => setShouldFetch(true)}
              sx={{
                color: theme.palette.secondary[300],
                fontSize: "25px",
                cursor: "pointer",
              }}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Grid item xs={12}>
            <TraderStatus traders={filteredTraders} />
            {/* {currentTab === "pending" && <TraderStatus status="pending" />}
            {currentTab === "review" && <TraderStatus status="review" />}
            {currentTab === "active" && <TraderStatus status="active" />}
            {currentTab === "disabled" && <TraderStatus status="disabled" />}
            {currentTab === "all" && <TraderStatus status="all" />} */}
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default TradersStatus;
