import React from "react";
import { CssBaseline } from "@mui/material";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";

import { store } from "./app/store";
import { Provider } from "react-redux";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ConfigProvider } from "./context/ConfigContext";

if (process.env.NODE_ENV === "production") disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <BrowserRouter> */}
      <ConfigProvider>
        <App />
      </ConfigProvider>
      {/* <Routes>
          <Route path="/*" element={<App />} />
        </Routes> */}
      {/* </BrowserRouter> */}

      {/* <CssBaseline />
      <App /> */}
    </Provider>
  </React.StrictMode>
);
