import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../../components/Header";
import { DownloadOutlined } from "@mui/icons-material";
import { pink } from "@mui/material/colors";

import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";

// import StatBox from "../../components/StatBox";
import FlexBetween from "../../components/FlexBetween";
import {
  useApproveOrRejectMutation,
  useChangeJobStatusMutation,
} from "../admin/adminApiSlice";

import DateObject from "../../util/DateObject";

const JobStatusCountsDisplay = ({ jobStatusCounts }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Job Status Counts
      </Typography>
      {Object.keys(jobStatusCounts).map((status) => (
        <Chip
          key={status}
          label={`${status.charAt(0).toUpperCase() + status.slice(1)}: ${
            jobStatusCounts[status]
          }`}
          variant="outlined"
          sx={{ margin: 1 }}
        />
      ))}
    </Box>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ToDo: move to db
const rejectedReasons = [
  { key: "invalid_record", value: "Invalid Record" },
  { key: "too_many_records", value: "Too Many Records" },
  { key: "too_few_records", value: "Too Few Records" },
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function StatusChangeComponent({ currentStatus, onChangeStatus }) {
  const [newStatus, setNewStatus] = React.useState(currentStatus);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl
        variant="outlined"
        style={{ marginRight: "16px", minWidth: "120px" }}
      >
        <Select
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
          sx={{ color: "black" }}
        >
          <MenuItem value="review">Review</MenuItem>
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="assigned">Assigned</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="rejected">Rejected</MenuItem>
          <MenuItem value="disabled">Disabled</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" onClick={() => onChangeStatus(newStatus)}>
        Change Status
      </Button>
    </Box>
  );
}

const HomeOwnerInfo = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [approveOrReject] = useApproveOrRejectMutation();
  const [changeJobStatus] = useChangeJobStatusMutation();

  const [rejectedValue, setRejectedValue] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRejectedChange = (event) => {
    const {
      target: { value },
    } = event;

    setRejectedValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [selectedValue, setSelectedValue] = useState();
  const handleChange = (event) => {
    setRejectedValue([]);
    setSelectedValue(event.target.value);

    // Only disabled - add to the rejectedValue without any explanation, if needed select from dropdown
    if (event.target.value === "disabled") {
      setRejectedValue(["disabled"]);
    }
  };

  // BUTTON ENABLE/DISABLE
  let rejectedApprovedBtn = false;
  if (selectedValue) {
    if (selectedValue === "rejected") {
      if (rejectedValue?.length > 0) rejectedApprovedBtn = true;
      else rejectedApprovedBtn = false;
    } else {
      rejectedApprovedBtn = true;
    }
  }

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const categoriesColumns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.5,
    // },
    // {
    //   field: "category_id",
    //   headerName: "Category ID",
    //   flex: 0.5,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "slug",
      headerName: "Slug",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
    },
  ];
  // const showDateForHandler = (id) => {
  //   setShowCurrentSubCategories(id);
  //   const subCats = state.categories.find((cat) => cat.id === id);
  //   setSelectedSubCategories(subCats?.sub_categories);
  // };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const getReasonKey = (value) => {
    const reasonObj = rejectedReasons.find((reason) => reason.value === value);
    return reasonObj ? reasonObj.key : null;
  };

  const approveOrRejectHandler = async () => {
    const reasonKeys = rejectedValue
      .map(getReasonKey)
      .filter((key) => key !== null);

    const reasonObject = {
      id: state._id,
      userId: state.userId,
      status: selectedValue,
      reason: reasonKeys,
    };

    await approveOrReject(reasonObject);
  };
  const updateJobStatusHandler = async (newStatus, obj) => {
    const { data } = await changeJobStatus({
      status: newStatus,
      _id: obj?._id,
    });
    const { code, status, message, result } = data || {};

    setExpandedRow(null);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <ArrowBackTwoToneIcon
          onClick={() => navigate(-1)}
          sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
        />
        <FlexBetween>
          <Header
            title="Home Owner Info"
            subtitle={`${state?.firstName} ${state?.lastName}`}
          />
        </FlexBetween>

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween>

      {/* Chip & Radio */}
      <Box m="0.5rem 0">
        <FormControl>
          {/* <FormLabel id="approve-reject-row-radio-buttons-group-label">
            Approve / Reject
          </FormLabel> */}

          <Box sx={{ display: "flex" }}>
            {selectedValue && selectedValue === "rejected" && (
              <Box m="0 0.5rem">
                <InputLabel id="demo-multiple-chip-label">
                  Rejected Reasons
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  autoWidth
                  value={rejectedValue}
                  onChange={handleRejectedChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  sx={{ minWidth: "150px" }}
                  MenuProps={MenuProps}
                >
                  {rejectedReasons.map((reasonObj) => (
                    <MenuItem
                      key={reasonObj?.key}
                      value={reasonObj?.value}
                      style={getStyles(reasonObj?.value, rejectedValue, theme)}
                    >
                      {reasonObj?.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            <RadioGroup
              row
              aria-labelledby="approve-reject-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {state?.status !== "active" && (
                <FormControlLabel
                  value="active"
                  control={
                    <Radio
                      {...controlProps("active")}
                      size="small"
                      color="success"
                    />
                  }
                  label="Approve"
                />
              )}
              <FormControlLabel
                value="rejected"
                control={
                  <Radio
                    {...controlProps("rejected")}
                    size="small"
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Reject"
              />
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    {...controlProps("disabled")}
                    size="small"
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Disabled"
              />
            </RadioGroup>
            <Button
              onClick={approveOrRejectHandler}
              disabled={!rejectedApprovedBtn}
              sx={{
                backgroundColor: selectedValue ? "blue" : "ping",
                color: selectedValue ? "yellow" : "green",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "1px 2px",

                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#3c52b2",
                },
              }}
            >
              {/* <DownloadOutlined sx={{ mr: "10px" }} /> */}
              Approve/Reject
            </Button>
          </Box>
        </FormControl>
      </Box>

      <Box sx={{ p: "0.2rem", border: "1px solid red" }}>
        <FlexBetween>
          <Typography variant="h1" display="block" gutterBottom>
            Status: {state?.status}
          </Typography>
          <Typography variant="h1" display="block" gutterBottom>
            {state?.jobs?.length} Jobs
          </Typography>
          {state?.rating && (
            <Typography variant="h1" display="block" gutterBottom>
              Rating: {state?.rating}
            </Typography>
          )}
        </FlexBetween>
      </Box>

      <JobStatusCountsDisplay jobStatusCounts={state?.jobStatusCounts} />
      {state?.status && state?.status !== "review" ? (
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ backgroundColor: "plum", color: "black" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="Jobs-list">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Job Description</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Interested</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.jobs &&
                  state?.jobs?.map((obj, index) => (
                    <React.Fragment key={obj?._id}>
                      <TableRow
                        key={obj?._id}
                        hover
                        onClick={() =>
                          setExpandedRow(
                            expandedRow === obj._id ? null : obj._id
                          )
                        }
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                            cursor: "pointer",
                          },
                          backgroundColor:
                            index % 2 === 0
                              ? "rgba(0, 0, 0, 0.02)"
                              : "transparent",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: obj?.status === "review" ? "green" : "blue",
                          }}
                        >
                          {obj?.status}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "black" }}
                        >
                          {obj?.category?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj?.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj?.location?.postCode}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj?.isStartDateFlexible === false ? (
                            <DateObject dt={obj.startDate} />
                          ) : (
                            "Yes"
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <DateObject dt={obj?.createdAt} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj?.interested?.length}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj?.assignedTo?.firstName}{" "}
                          {obj?.assignedTo?.lastName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj?.status}
                        </TableCell>
                      </TableRow>

                      {expandedRow === obj._id && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <Card
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: 350,
                                  marginBottom: 2,
                                }}
                              >
                                <CardContent>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography variant="h6">
                                      {obj?.title}
                                    </Typography>
                                    {/* <Checkbox
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                /> */}
                                  </Box>
                                  <Typography variant="h6">
                                    {obj?.description}
                                  </Typography>
                                  <Typography variant="h6">
                                    {obj?.assignedTo?.firstName}{" "}
                                    {obj?.assignedTo?.lastName}
                                  </Typography>
                                  <Typography variant="h6">
                                    Status: {obj?.status}
                                  </Typography>
                                  <Typography variant="body2">
                                    Active Since:{" "}
                                    <DateObject dt={obj?.createdAt} />
                                  </Typography>
                                </CardContent>
                              </Card>
                              <IconButton
                                sx={{
                                  display: "flex",
                                  alignSelf: "flex-start",
                                  color: "black",
                                }}
                                onClick={() => setExpandedRow(false)}
                              >
                                <CloseTwoToneIcon />
                              </IconButton>

                              <StatusChangeComponent
                                currentStatus={obj?.status}
                                onChangeStatus={(newStatus) =>
                                  updateJobStatusHandler(newStatus, obj)
                                }
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <Typography variant="h2" display="block" gutterBottom>
            Either Approve, Reject or Disabled the Home Owner first
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default HomeOwnerInfo;
