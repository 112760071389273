import { createSlice } from "@reduxjs/toolkit";
import { adminApiSlice } from "../features/admin/adminApiSlice";

const initialState = {
  mode: "dark",
  userId: "63ae241054d3c7977665ad07", //"63701cc1f03239b7f700000e",
  appState: "",
  allTraders: [],
  traderInfo: null,

  allHomeOwners: [],
  // sideBarFlag: false,
  // postCodeNotification: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },

    setAppState: (state, action) => {
      state.appState = action.payload;
    },

    setAllTraders: (state, action) => {
      state.allTraders = action.payload;
    },
    setTraderInfo: (state, action) => {
      state.traderInfo = action.payload;
    },

    setAllHomeOwners: (state, action) => {
      state.allHomeOwners = action.payload;
    },
  },
});

export const { setAppState } = globalSlice.actions;

export const { setMode } = globalSlice.actions;

export const { setAllTraders } = globalSlice.actions;
export const getAllTraders = (state) => {
  return state.global.allTraders;
};

export const { setTraderInfo } = globalSlice.actions;

export const selectCurrentTrader = (state) => {
  return state.global.traderInfo;
};

export const { setAllHomeOwners } = globalSlice.actions;
export const getAllHomeOwners = (state) => {
  return state.global.allHomeOwners;
};

export default globalSlice.reducer;
