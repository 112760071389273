import React, { useState, useEffect, useRef } from "react";
import { useExtraSecurityQRCodeMutation } from "./authApiSlice";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  TextField,
} from "@mui/material";
import {
  AccessAlarm,
  Accessibility,
  AccountBalance,
  AccountCircle,
  Add,
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  AttachFile,
  Bluetooth,
  Bookmark,
  Build,
  Camera,
  Check,
  Cloud,
  Code,
  Delete,
  Edit,
  Email,
  Event,
  Favorite,
  FileCopy,
  Home,
  Lock,
  Map,
  Notifications,
  Person,
  Settings,
  ShoppingCart,
} from "@mui/icons-material";

const icons = [
  { icon: AccessAlarm, color: "red" },
  { icon: Accessibility, color: "blue" },
  { icon: AccountBalance, color: "green" },
  { icon: AccountCircle, color: "orange" },
  { icon: Add, color: "purple" },
  { icon: ArrowBack, color: "teal" },
  { icon: ArrowDownward, color: "pink" },
  { icon: ArrowForward, color: "brown" },
  { icon: ArrowUpward, color: "cyan" },
  { icon: AttachFile, color: "indigo" },
  { icon: Bluetooth, color: "lime" },
  { icon: Bookmark, color: "brown" },
  { icon: Build, color: "red" },
  { icon: Camera, color: "blue" },
  { icon: Check, color: "green" },
  { icon: Cloud, color: "orange" },
  { icon: Code, color: "purple" },
  { icon: Delete, color: "teal" },
  { icon: Edit, color: "pink" },
  { icon: Email, color: "brown" },
  { icon: Event, color: "cyan" },
  { icon: Favorite, color: "indigo" },
  { icon: FileCopy, color: "lime" },
  { icon: Home, color: "brown" },
  { icon: Lock, color: "red" },
  { icon: Map, color: "blue" },
  { icon: Notifications, color: "green" },
  { icon: Person, color: "orange" },
  { icon: Settings, color: "purple" },
  { icon: ShoppingCart, color: "teal" },
];

const getRandomMaterialIcon = () => {
  const randomIndex = Math.floor(Math.random() * icons.length);
  const { icon: Icon, color } = icons[randomIndex];
  return <Icon sx={{ fontSize: 48, color }} />;
};

const CardComponent = ({ number, isSelected, onClick }) => {
  const icon = getRandomMaterialIcon();

  return (
    <Card
      onClick={onClick}
      sx={{
        maxWidth: 100,
        minHeight: 100,
        textAlign: "center",
        cursor: "pointer",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent>
        {icon}
        <Typography
          variant="h5"
          component="div"
          sx={{
            position: "absolute",
            bottom: "8px",
            right: "8px",
            fontWeight: "bold",
          }}
        >
          {number}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ExtraSecurity = ({ verified }) => {
  const [extraSecurityQRCode] = useExtraSecurityQRCodeMutation();

  const [roko, setRoko] = useState("");

  const [selectedCards, setSelectedCards] = useState([]);
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const rokoRef = useRef();

  useEffect(() => {
    rokoRef.current?.focus();
  }, []);

  const handleRokoInput = (e) => setRoko(e.target.value);

  const handleCardClick = (number) => {
    // Add the number to the selectedCards array
    setSelectedCards([...selectedCards, number]);
  };

  const handleCancelClick = () => {
    // Clear all selected cards
    setSelectedCards([]);
  };

  const renderCards = () => {
    return numbers.map((number) => {
      const isSelected = selectedCards.includes(number);
      return (
        <Grid item xs={12} sm={6} md={4} key={number}>
          <CardComponent
            number={number}
            isSelected={isSelected}
            onClick={() => handleCardClick(number)}
          />
        </Grid>
      );
    });
  };

  const submitHandler = async () => {
    const { data } = await extraSecurityQRCode({
      code: selectedCards.join(""),
      roko,
    });
    const { code, status, message, result } = data || {};

    verified(status === "success" ? true : false);
    setSelectedCards([]);
  };

  const isButtonDisabled = roko.length < 15 || selectedCards.length === 0;

  return (
    <div>
      <Grid container spacing={2}>
        {renderCards()}
      </Grid>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        sx={{
          maxWidth: 300,
          minHeight: 150,
          textAlign: "center",
          cursor: "pointer",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="roko"
          ref={rokoRef}
          value={roko}
          onChange={handleRokoInput}
          label="Roko"
          name="roko"
          autoFocus
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={submitHandler}
            disabled={isButtonDisabled}
          >
            Submit
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Box>
      </Box>

      <div>
        Selected Cards [{selectedCards?.length}]:{" "}
        {selectedCards.map((card) => card + " ")}
      </div>
    </div>
  );
};

export default ExtraSecurity;
