import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: "/api/auth/adminlogin",
          method: "POST",
          body: credentials,
        };
      },
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: "/api/auth/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(logOut());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/api/auth/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { code, status, message, result: accessToken } = data || {};
          // const { accessToken } = data;

          dispatch(setCredentials({ accessToken }));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    authenticateQRCode: builder.mutation({
      query: (args) => {
        return {
          url: `/api/auth/authenticateQRCode`,
          method: "POST",
          body: args,
        };
      },
    }),
    extraSecurityQRCode: builder.mutation({
      query: (args) => {
        return {
          url: "/api/auth/extraSecurityQRCode",
          method: "POST",
          body: args,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSendLogoutMutation,
  useRefreshMutation,
  useAuthenticateQRCodeMutation,
  useExtraSecurityQRCodeMutation,
} = authApiSlice;
