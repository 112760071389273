import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      // headers.set("Cookie", "myCookie=cookieValue");
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // console.log(args) // request url, method, body
  // console.log(api) // signal, dispatch, getState()
  // console.log(extraOptions) //custom like {shout: true}

  let result = await baseQuery(args, api, extraOptions);

  // If you want, handle other status codes, too
  // if (result?.error?.status === 403) {
  if (result?.data?.code === 403) {
    console.log("sending refresh token");

    // send refresh token to get new access token
    // const refreshResult = await baseQuery(
    //   "/api/auth/refresh",
    //   api,
    //   extraOptions
    // );
    const { data } = await baseQuery("/api/auth/refresh", api, extraOptions);
    const { code, status, message, result: accessToken } = data || {};

    // if (refreshResult?.data) {
    if (accessToken) {
      // store the new token
      // api.dispatch(setCredentials({ ...refreshResult.data }));
      api.dispatch(setCredentials({ accessToken }));

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // if (refreshResult?.error?.status === 403) {
      //   refreshResult.error.data.message = "Your login has expired.";
      // }
      // return refreshResult;
      return data;
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Note", "User"],
  endpoints: (builder) => ({}),
});
