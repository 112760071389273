import React from "react";

import { Grid, useTheme } from "@mui/material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

function StatusRow({ status, errorMessage }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="body1">Job:</Typography>
        {Object.entries(status.Job).map(([key, value]) => (
          <Typography key={key} variant="body2">
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">User:</Typography>
        {Object.entries(status.User).map(([key, value]) => (
          <Typography key={key} variant="body2">
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">Trader:</Typography>
        {Object.entries(status.Trader).map(([key, value]) => (
          <Typography key={key} variant="body2">
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">HomeOwner:</Typography>
        {Object.entries(status.HomeOwner).map(([key, value]) => (
          <Typography key={key} variant="body2">
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>

      {errorMessage && (
        <Grid item xs={3}>
          <Typography variant="body1">Error:</Typography>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

const SSE = ({ messages, errorMessage, setSseRetry, setSseConnected }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography
        fontWeight="bold"
        fontSize="0.85rem"
        sx={{ color: theme.palette.secondary[100], mr: 1 }}
      >
        SSE
      </Typography>
      {messages && (
        <StatusRow status={messages?.status} errorMessage={errorMessage} />
      )}
      {/*     
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
        {messages &&
          Object.keys(messages)?.map((key, index) => {
            console.log(
              "messages: ",
              messages,
              " - key: ",
              key,
              " - messages[key] : ",
              messages[key]
            );
            return (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="subtitle1">{key}</Typography>
                <Typography variant="body1">{messages[key]}</Typography>
              </Box>
            );
          })}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Box> */}

      <Tooltip title="Refresh SSE">
        <IconButton
          onClick={() => {
            setSseRetry((prev) => !prev);
            setSseConnected(true);
          }}
        >
          <RefreshTwoToneIcon
            sx={{
              color: theme.palette.secondary[300],
              fontSize: "25px",
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title="Stop / Cancel SSE">
        <IconButton onClick={() => setSseConnected(false)}>
          <CancelTwoToneIcon
            sx={{
              color: theme.palette.secondary[300],
              fontSize: "25px",
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SSE;
