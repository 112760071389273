import { store } from "../../app/store";
import {
  adminApiSlice,
  useGetAllHomeOwnersQuery,
  useGetAllTradersQuery,
} from "../admin/adminApiSlice";
import { useDispatch } from "react-redux";

// import { notesApiSlice } from "../notes/notesApiSlice";
// import { usersApiSlice } from "../users/usersApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  fetchAllTraders,
  setAllHomeOwners,
  setAllTraders,
} from "../../app/state";

const Prefetch = () => {
  const dispatch = useDispatch();
  // const { data: { error = {}, data: traders } = {}, isError } =
  //   useGetAllTradersQuery();
  const { data } = useGetAllTradersQuery();
  const { code, status, message, result: traders } = data || {};

  const { data: hoData } = useGetAllHomeOwnersQuery();
  const {
    code: hoCode,
    status: hoStatus,
    message: hoMessage,
    result: hoResult,
  } = hoData || {};

  useEffect(() => {
    if (traders) {
      dispatch(setAllTraders(traders));
    }

    if (hoResult) {
      dispatch(setAllHomeOwners(hoResult));
    }
    // store.dispatch(
    //   notesApiSlice.util.prefetch("getNotes", "notesList", { force: true })
    // );
    // store.dispatch(
    //   usersApiSlice.util.prefetch("getUsers", "usersList", { force: true })
    // );

    // dispatch(adminApiSlice.endpoints.getAllTraders.initiate());
    // store.dispatch(
    //   adminApiSlice.util.prefetch("getAllTraders", "tradersList", {
    //     force: true,
    //   })
    // );
  }, [traders, dispatch]);

  if (
    status?.toLowerCase() === "error" ||
    hoStatus?.toLowerCase() === "error"
  ) {
    return <div>Error: {message}</div>;
  }

  return <Outlet />;
};
export default Prefetch;
