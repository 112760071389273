import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);

  let isHomeOwner = false;
  let isManager = false;
  let isAdmin = false;
  let isTrader = false;
  let status = "";

  if (token) {
    const decoded = jwtDecode(token);
    const { user_name, roles } = decoded.UserInfo;

    isHomeOwner = roles.includes("HomeOwner");
    isTrader = roles.includes("Trader");
    isManager = roles.includes("Manager");
    isAdmin = roles.includes("Admin");

    if (isHomeOwner) status = "HomeOwner";
    if (isTrader) status = "Trader";
    if (isManager) status = "Manager";
    if (isAdmin) status = "Admin";

    return {
      user_name,
      roles,
      status,
      isHomeOwner,
      isTrader,
      isManager,
      isAdmin,
    };
  }

  return {
    user_name: "",
    roles: [],
    isHomeOwner,
    isManager,
    isAdmin,
    isTrader,
    status,
  };
};
export default useAuth;
