// useSSE.js

import { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
// import usePersistObject from "./usePersistObject";

const useSSE = (url, sseRetry, sseConnected) => {
  const { config, setConfig } = useConfig();

  const [messages, setMessages] = useState(config.sse || null);
  const [errorMessage, setErrorMessage] = useState(null);

  // const getAlias = useCallback((eventType) => {
  //   const eventTypeAliases = {
  //     user_find: "UF",
  //     user_save: "US",
  //     trader_save: "TS",
  //     homeOwner_save: "HS",
  //     job_save: "JS",
  //   };

  //   return eventTypeAliases[eventType] || eventType;
  // }, []);

  useEffect(() => {
    if (!sseConnected) {
      return;
    }
    const parse = async (data) => {
      if (!data) {
        return;
      }

      try {
        const parsedData = JSON.parse(data);
        if (parsedData && typeof parsedData === "object") {
          const eventType = Object.keys(parsedData)[0];
          const eventData = parsedData[eventType];

          // const alias = getAlias(eventType);
          const alias = eventType;
          const obj = { [`${alias}`]: eventData };

          setMessages((prevMessages) => ({
            ...prevMessages,
            ...obj,
          }));
          setConfig((prevConfig) => ({
            ...prevConfig,
            sse: {
              ...prevConfig.sse,
              ...parsedData,
            },
          }));
        } else {
          setErrorMessage("Invalid data");
        }
      } catch (e) {
        setErrorMessage("Error parsing data: ", e.toString());
      }
    };

    const evs = new EventSource(url, {
      withCredentials: true,
    });

    evs.onmessage = (ev) => {
      parse(ev.data);
    };

    evs.addEventListener("server-error", (e) => {
      const data = JSON.parse(e.data);

      setErrorMessage(`Server error: ${data.message}, Error: ${data.error}`);
    });

    evs.onerror = (e) => {
      if (e) {
        // console.log("USE SSE ERROR ON ERROR: ", e);
        // setErrorMessage("Err: ", e.toString());
      }

      if (!sseRetry) {
        evs.close();
      }
    };

    evs.onopen = () => {
      // console.log("Listening...");
    };

    return () => {
      // console.log("Closing...");
      setErrorMessage(null);
      //setMessages(null);
      evs.close();
    };
  }, [url, sseRetry, sseConnected, setConfig]);

  return { messages, errorMessage };
};

export default useSSE;
