import FlexBetween from "./FlexBetween";

const { Box } = require("@mui/material");

const FlexBetweenTwo = ({ children }) => {
  return (
    <FlexBetween>
      <Box flexGrow={9} bgcolor="primary.main">
        {children[0]}
      </Box>
      <Box flexGrow={1}>{children[1]}</Box>
    </FlexBetween>
  );
};

export default FlexBetweenTwo;
