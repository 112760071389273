import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../../components/Header";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  TrafficTwoTone,
} from "@mui/icons-material";
import { pink } from "@mui/material/colors";

import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import StatBox from "../../components/StatBox";
import FlexBetween from "../../components/FlexBetween";
import { useApproveOrRejectMutation } from "../admin/adminApiSlice";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";

import DateObject from "../../util/DateObject";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ToDo: move to db
const rejectedReasons = [
  { key: "invalid_record", value: "Invalid Record" },
  { key: "too_many_records", value: "Too Many Records" },
  { key: "too_few_records", value: "Too Few Records" },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TraderInfo = () => {
  // const allTraders = useSelector(getAllTraders);

  const { state } = useLocation();
  const navigate = useNavigate();
  const [approveOrReject, { data, isLoading, isSuccess, isError, error }] =
    useApproveOrRejectMutation();

  const [showCurrentSubCategories, setShowCurrentSubCategories] = useState();
  const [selectedSubCategories, setSelectedSubCategories] = useState();

  const [rejectedValue, setRejectedValue] = useState([]);
  const handleRejectedChange = (event) => {
    const {
      target: { value },
    } = event;
    setRejectedValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [selectedValue, setSelectedValue] = useState();
  const handleChange = (event) => {
    setRejectedValue([]);
    setSelectedValue(event.target.value);

    // Only disabled - add to the rejectedValue without any explanation, if needed select from dropdown
    if (event.target.value === "disabled") {
      setRejectedValue(["disabled"]);
    }
  };

  // BUTTON ENABLE/DISABLE
  let rejectedApprovedBtn = false;
  if (selectedValue) {
    if (selectedValue === "rejected") {
      if (rejectedValue?.length > 0) rejectedApprovedBtn = true;
      else rejectedApprovedBtn = false;
    } else {
      rejectedApprovedBtn = true;
    }
  }

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const categoriesColumns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.5,
    // },
    // {
    //   field: "category_id",
    //   headerName: "Category ID",
    //   flex: 0.5,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "slug",
      headerName: "Slug",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
    },
  ];
  const showDateForHandler = (id) => {
    setShowCurrentSubCategories(id);
    const subCats = state.categories.find((cat) => cat?._id === id);
    setSelectedSubCategories(subCats?.subcategories);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  // const approveOrRejectHandler = async () => {
  //   const reasonObject = {
  //     id: state._id,
  //     userId: state.userId,
  //     flag: selectedValue === "approve" ? true : false,
  //     reason: rejectedValue,
  //   };
  //   await approveOrReject(reasonObject);
  // };
  const getReasonKey = (value) => {
    const reasonObj = rejectedReasons.find((reason) => reason.value === value);
    return reasonObj ? reasonObj.key : null;
  };

  const approveOrRejectHandler = async () => {
    const reasonKeys = rejectedValue
      .map(getReasonKey)
      .filter((key) => key !== null);

    const reasonObject = {
      id: state._id,
      userId: state.userId,
      status: selectedValue,
      reason: reasonKeys,
    };

    await approveOrReject(reasonObject);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <ArrowBackTwoToneIcon
          onClick={() => navigate(-1)}
          sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
        />
        <FlexBetween>
          <Header
            title="Trader Info"
            subtitle={`${state?.firstName} - ${state?.lastName}`}
          />
        </FlexBetween>

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween>

      {/* Chip & Radio */}
      <Box m="0.5rem 0">
        <FormControl>
          {/* <FormLabel id="approve-reject-row-radio-buttons-group-label">
            Approve / Reject
          </FormLabel> */}

          <Box sx={{ display: "flex" }}>
            {selectedValue && selectedValue === "rejected" && (
              <Box m="0 0.5rem">
                <InputLabel id="demo-multiple-chip-label">
                  Rejected Reasons
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  autoWidth
                  value={rejectedValue}
                  onChange={handleRejectedChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  sx={{ minWidth: "150px" }}
                  MenuProps={MenuProps}
                >
                  {rejectedReasons.map((reasonObj) => (
                    <MenuItem
                      key={reasonObj?.key}
                      value={reasonObj?.value}
                      style={getStyles(reasonObj?.value, rejectedValue, theme)}
                    >
                      {reasonObj?.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            <RadioGroup
              row
              aria-labelledby="approve-reject-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {state?.status !== "active" && (
                <FormControlLabel
                  value="active"
                  control={
                    <Radio
                      {...controlProps("active")}
                      size="small"
                      color="success"
                    />
                  }
                  label="Approve"
                />
              )}
              <FormControlLabel
                value="rejected"
                control={
                  <Radio
                    {...controlProps("rejected")}
                    size="small"
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Reject"
              />
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    {...controlProps("disabled")}
                    size="small"
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Disabled"
              />
            </RadioGroup>
            <Button
              onClick={approveOrRejectHandler}
              disabled={!rejectedApprovedBtn}
              sx={{
                backgroundColor: selectedValue ? "blue" : "ping",
                color: selectedValue ? "yellow" : "green",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "1px 2px",

                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#3c52b2",
                },
              }}
            >
              {/* <DownloadOutlined sx={{ mr: "10px" }} /> */}
              Approve/Reject
            </Button>
          </Box>
        </FormControl>
      </Box>

      <Box sx={{ p: "0.2rem", border: "1px solid red" }}>
        <FlexBetween>
          <Typography variant="h1" display="block" gutterBottom>
            Status: {state?.status}
          </Typography>
          <Typography variant="h1" display="block" gutterBottom>
            Categories: {state?.categories?.length}
          </Typography>
          {state?.rating && (
            <Typography variant="h1" display="block" gutterBottom>
              Rating: {state?.rating}
            </Typography>
          )}
        </FlexBetween>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: 2,
          gap: 5,
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 350,
            alignSelf: "flex-start",
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4">Info</Typography>
              <Typography variant="h6">
                {state?.firstName} {state?.lastName}
              </Typography>
              <Typography variant="h6">Email: {state?.email}</Typography>

              <Divider />

              <Typography variant="h6">
                Created at: <DateObject dt={state?.createdAt} />
              </Typography>
              <Typography variant="h6">
                Updated at: <DateObject dt={state?.updatedAt} />
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 350,
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4">Contact Info</Typography>
              <Typography variant="h6">
                Company Name: {state?.contactInfo?.companyName}
              </Typography>
              <Typography variant="h6">
                Primary Number: {state?.contactInfo?.primaryNumber}
              </Typography>
              <Divider />
              <Typography variant="h5">Address</Typography>
              <Typography variant="h6">
                {state?.contactInfo?.address?.address1}
              </Typography>
              <Typography variant="h6">
                {state?.contactInfo?.address?.address2}
              </Typography>
              <Typography variant="h6">
                {state?.contactInfo?.address?.city}
              </Typography>

              <Typography variant="h6">
                {state?.contactInfo?.address?.state}
              </Typography>
              <Typography variant="h6">
                {state?.contactInfo?.address?.postCode}
              </Typography>
              <Typography variant="h6">
                {state?.contactInfo?.address?.country}
              </Typography>

              <Typography variant="h6">
                Created at:{" "}
                <DateObject dt={state?.contactInfo?.address?.createdAt} />
              </Typography>
              <Typography variant="h6">
                Updated at:{" "}
                <DateObject dt={state?.contactInfo?.address?.updatedAt} />
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* ROW 1 */}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {state?.categories &&
          state.categories.map((cat) => {
            return (
              <StatBox
                key={cat._id}
                id={cat._id}
                title={cat?.category?.slug ?? "No Name"}
                value={cat?.category?.name ?? "No Name"}
                numberOfSubCategories={cat?.subcategories?.length ?? 0}
                description="SubCategories"
                icon={
                  showCurrentSubCategories === cat?._id ? (
                    <TrafficTwoTone
                      sx={{
                        color: "green",
                        fontSize: "26px",
                      }}
                    />
                  ) : (
                    <Traffic
                      sx={{
                        color: theme.palette.secondary[300],
                        fontSize: "26px",
                      }}
                    />
                  )
                }
                showDataFor={showDateForHandler}
              />
            );
          })}
      </Box>

      {selectedSubCategories && (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <Box
            gridColumn="span 12"
            gridRow="span 3"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
              cursor: "pointer",
            }}
          >
            <DataGrid
              //   loading={isLoading || !data}
              getRowId={(row) => row._id}
              rows={selectedSubCategories || []}
              columns={categoriesColumns}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TraderInfo;
