import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "../features/auth/authSlice";

import globalReducer from "./state";
import { globalApi } from "./api/apiQuery";

let devTools = true;

if (process.env.NODE_ENV === "production") {
  devTools = false;
}

export const store = configureStore({
  reducer: {
    global: globalReducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, globalApi.middleware),
  devTools: devTools,
});

setupListeners(store.dispatch);
