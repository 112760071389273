import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { selectTradersSlice } from "../../app/state";

const adminAdapter = createEntityAdapter({});

const initialState = adminAdapter.getInitialState();

export const adminApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Admin"],
  endpoints: (builder) => ({
    getAllTraders: builder.query({
      query: () => {
        return {
          url: "/api/admin/getAllTraders",
          method: "GET",
        };
      },
      // providesTags: ["Admin"],
      // invalidatesTags: [{ type: "Trader", id: "LIST" }],
    }),
    getAllAdmins: builder.query({
      query: () => ({
        url: "/api/admin/getAllAdmins",
        memmthod: "GET",
        validateStatus: (response, result) => {
          return response.code === 200;
        },
      }),
    }),
    approveOrReject: builder.mutation({
      query: (reason) => {
        return {
          url: "/api/admin/approveOrReject",
          method: "POST",
          body: reason,
        };
      },
    }),

    addCategory: builder.mutation({
      query: (category) => {
        return {
          url: "/api/admin/addCategory",
          method: "POST",
          body: category,
        };
      },
    }),
    updateCategory: builder.mutation({
      query: (category) => {
        return {
          url: "/api/admin/updateCategory",
          method: "PATCH",
          body: category,
        };
      },
    }),
    deleteCategory: builder.mutation({
      query: (category) => {
        return {
          url: "/api/admin/deleteCategory",
          method: "DELETE",
          body: category,
        };
      },
    }),
    addSubCategory: builder.mutation({
      query: (category) => {
        return {
          url: "/api/admin/addSubcategory",
          method: "POST",
          body: category,
        };
      },
    }),
    updateSubCategory: builder.mutation({
      query: (subcategory) => {
        return {
          url: "/api/admin/updateSubCategory",
          method: "PATCH",
          body: subcategory,
        };
      },
    }),
    deleteSubCategory: builder.mutation({
      query: (subcategory) => {
        return {
          url: "/api/admin/deleteSubcategory",
          method: "DELETE",
          body: subcategory,
        };
      },
    }),

    // ========== HOME OWNERS
    getAllHomeOwners: builder.query({
      query: () => {
        return {
          url: "/api/admin/getAllHomeOwners",
          meethod: "GET",
        };
      },
    }),
    changeJobStatus: builder.mutation({
      query: (status) => {
        return {
          url: "/api/admin/changeJobStatus",
          method: "PATCH",
          body: status,
        };
      },
    }),
    // getQRCode: builder.query({
    //   query: () => ({
    //     url: `/api/admin/getQRCode`,
    //   }),
    // }),
    // verify2Fa: builder.mutation({
    //   query: ({ code }) => ({
    //     url: `/api/admin/verify2fa/${code}`,
    //     method: "POST",
    //   }),
    // }),
  }),
});

export const {
  useGetAllTradersQuery,
  useGetAllAdminsQuery,
  useApproveOrRejectMutation,

  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,

  useGetAllHomeOwnersQuery,
  useChangeJobStatusMutation,
  // useGetQRCodeQuery,
  // useVerify2FaMutation,
} = adminApiSlice;

// returns the query result object
export const selectUsersResult =
  adminApiSlice.endpoints.getAllTraders.select("tradersList");

export const selectApiQueriesData = createSelector(
  selectUsersResult,

  (adminQueryResult) => adminQueryResult.data // normalized state object with ids & entities
);

export const {
  selectIds: selectAllIds,
  selectEntities: selectAllEntities,
  selectAll: selectAllUsers,
  selectById: selectUserById,
} = adminAdapter.getSelectors(
  (state) => selectApiQueriesData(state) ?? initialState
);
