import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Reaptcha from "reaptcha";
import { useDispatch } from "react-redux";

import {
  useAuthenticateQRCodeMutation,
  useLoginMutation,
} from "./authApiSlice";

import usePersist from "../../hooks/usePersist";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../../components/Copyright";
import PulseLoader from "react-spinners/PulseLoader";
import { Alert, Card, CardContent } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import ExtraSecurity from "./ExtraSecurity";

const Login = () => {
  const theme = createTheme();
  const { user_name, isManager, isAdmin, isTrader } = useAuth();

  const [login, { isLoading }] = useLoginMutation();
  const [authenticateQRCode, { data: fa2Status, isSuccess }] =
    useAuthenticateQRCodeMutation();

  const userRef = useRef();
  const fa2Ref = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [extraSecurityVerified, setExtraSecurityVerified] = useState(false);

  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const captchaRef = useRef(null);

  const [isQRValidated, setIsQRValidated] = useState(false);
  const [fa2, setFa2] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const [persist, setPersist] = usePersist();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  if (pathname !== "/login") {
    console.log("login - pathname != login: ", pathname);
    navigate("/");
  }

  const handle2FAChange = (event) => setFa2(event.target.value);

  // --------------------- QR Code Check ---------------------
  const fa2ConditionRef = useRef(false);
  useEffect(() => {
    const fa2ConditionCheck = async () => {
      if (fa2.trim().length === 6) {
        // const { data: validPin } = await authenticateQRCode({
        //   code: fa2,
        //   user_name: email,
        //   password,
        // });
        const { data } = await authenticateQRCode({
          code: fa2,
          user_name: email,
          password,
        });
        const { code, status, message, result: accessToken } = data || {};

        if (status === "success" && accessToken) {
          setFa2("");
          // dispatch(setCredentials(accessToken));
          return navigate("/secure/dashboard");
        }
      }
    };

    if (fa2ConditionRef.current === false) {
      fa2ConditionCheck();
      fa2ConditionRef.current = true;
    }
    return () => (fa2ConditionRef.current = false);
  }, [fa2, authenticateQRCode, email, navigate, password]);

  useEffect(() => {
    userRef.current?.focus();
    fa2Ref.current?.focus();
  }, []);

  const handleVerify = (captchaResponse) => {
    setRecaptchaResponse(captchaResponse);
  };

  const onExpireHandler = () => {
    captchaRef?.current?.reset();
    setRecaptchaResponse("");
  };

  /*
    Handle Submit
  */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setEmail(data.get("email"));
    setPassword(data.get("password"));

    try {
      const { code, message, status } = await login({
        user_name: data.get("email"),
        password: data.get("password"),
      }).unwrap();

      if (status === "success" && message === "Authenticated") {
        setIsQRValidated(true);
      }
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
      errRef.current?.focus();
    }
  };

  const handleUserInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  const errClass = errMsg ? errMsg : "offscreen";

  if (isLoading) return <PulseLoader color={"#FFF"} />;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {extraSecurityVerified ? (
          <Box>
            {!isQRValidated ? (
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Typography component="h1" variant="h5" ref={errRef}>
                  {errMsg}
                </Typography>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    ref={userRef}
                    value={email}
                    onChange={handleUserInput}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handlePwdInput}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        color="primary"
                        id="persist"
                        onChange={handleToggle}
                        checked={persist}
                      />
                    }
                    label="Trust This Device"
                  />

                  <Reaptcha
                    ref={(e) => (captchaRef.current = e)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onVerify={handleVerify}
                    onError={console.error}
                    onExpire={onExpireHandler}
                  />
                  <Button
                    type="submit"
                    disabled={
                      !email?.trim() || !password?.trim() // || !recaptchaResponse
                    }
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center">
                <TextField
                  margin="normal"
                  required
                  // fullWidth
                  sx={{ width: "20ch" }}
                  id="fa2"
                  ref={fa2Ref}
                  value={fa2}
                  onChange={handle2FAChange}
                  label="Enter Your Name"
                  name="qrCode"
                  autoFocus
                />
              </Box>
            )}
            {/* ToDo */}
            {errMsg && (
              <Alert variant="filled" severity="error">
                {errClass}
              </Alert>
            )}
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        ) : (
          <ExtraSecurity
            verified={(isVerified) => setExtraSecurityVerified(isVerified)}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Login;
