import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DashHeader from "./DashHeader";
import DashFooter from "./DashFooter";
import useAuth from "../hooks/useAuth";

const SecureLayout = () => {
  const { isManager, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin)
      navigate("/", { state: { err: "Unauthorized access to DashLayout" } });
  }, [isAdmin, navigate]);

  const content = isAdmin ? <Outlet /> : <Link to="/" />;

  return content;
};
export default SecureLayout;
