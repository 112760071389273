import React, { useEffect, useState } from "react";

import { useMediaQuery, Container, Tabs, Tab, Grid, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import useAuth from "../../hooks/useAuth";
import HomeOwnerStatus from "./HomeOwnerStatus";
import { useDispatch, useSelector } from "react-redux";
import { getAllHomeOwners, setAllHomeOwners } from "../../app/state";
import { useGetAllHomeOwnersQuery } from "../../features/admin/adminApiSlice";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

// ToDo: Get from traders status
const tabsGroup = [
  { value: "pending", label: "Pending" },
  { value: "review", label: "Review" },
  { value: "active", label: "Active" },
  { value: "rejected", label: "Rejected" },
  { value: "disabled", label: "Disabled" },
  { value: "all", label: "All" },
];

const HomeOwnersStatus = () => {
  const dispatch = useDispatch();
  const allHomeOwners = useSelector(getAllHomeOwners);
  const [shouldFetch, setShouldFetch] = useState(false);

  // const { data, refetch } = useGetAllHomeOwnersQuery(undefined, {
  //   skip: !shouldFetch,
  // });
  const { data, refetch } = useGetAllHomeOwnersQuery();

  //const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("all"); // change to pending
  const [tabs, setTabs] = useState(tabsGroup);
  const navigate = useNavigate();
  const [homeOwners, setHomeOwners] = useState([]);

  const { user_name, isManager, isAdmin } = useAuth();
  const theme = createTheme();

  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    setTabs(tabsGroup);
    setCurrentTab("all");
  }, []);

  useEffect(() => {
    const callRefetch = async () => {
      const { data } = await refetch();
      const { code, status, message, result } = data || {};

      dispatch(setAllHomeOwners(result));
      setShouldFetch(false);
    };
    if (shouldFetch) {
      callRefetch();
    }
  }, [dispatch, refetch, shouldFetch]);
  useEffect(() => {
    if (data) {
      const { code, status, message, result } = data || {};
      dispatch(setAllHomeOwners(result));
      setShouldFetch(false);
    }
  }, [data, dispatch]);

  const filteredHomeOwners =
    currentTab === "all"
      ? allHomeOwners
      : allHomeOwners?.filter((ho) => ho.status === currentTab);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        {tabs && (
          <Box
            sx={{
              paddingTop: "20px",
              paddingLeft: "20px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid item xs={6}>
              <TabsWrapper
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map((tab) => {
                  return (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      // disabled={disabledFlag}
                    />
                  );
                })}
              </TabsWrapper>
            </Grid>

            <RefreshTwoToneIcon
              onClick={() => setShouldFetch(true)}
              sx={{
                color: theme.palette.secondary[300],
                fontSize: "25px",
                cursor: "pointer",
              }}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Grid item xs={12}>
            <HomeOwnerStatus homeOwners={filteredHomeOwners} />
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default HomeOwnersStatus;
