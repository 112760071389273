import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiQueryAdapter = createEntityAdapter({});
const initialState = apiQueryAdapter.getInitialState();

export const globalApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }), // "http://localhost:3500" }),
  reducerPath: "splitApi",
  tagTypes: [
    "TradersById",
    "NewRegisteredTrader",
    "Categories",
    "SubCategories",
  ],
  endpoints: (builder) => ({
    getTraderById: builder.mutation({
      query: (id) => {
        return {
          url: `/api/public/traderById/${id}`,
          method: "GET",
        };
      },
      transformResponse: (responseData) => {
        const { error, data } = responseData; // ToDo: error
        return data;
      },
      invalidatesTags: { type: "TraderById", id: "OBJECT" },
      providesTags: ["TraderById"],
    }),
    getAllCategories: builder.query({
      query: () => `public/categories`,
      providesTags: ["Categories"],
    }),
    getAllSubCategories: builder.mutation({
      query: (id) => {
        return {
          url: `/api/public/sub_categories/${id}`,
          method: "GET",
        };
      },
      transformResponse: (responseData) => {
        const { error, data } = responseData; // ToDo: error
        return data;

        // const loadedSubCategories = responseData.map((subCategory) => {
        //   return sub_category;
        // });
        // return apiQueryAdapter.setAll(initialState, loadedSubCategories);
      },
      // invalidatesTags: [{ type: "SubCategory", id: "LIST" }],
      providesTags: ["SubCategory"],
    }),
    // getTradersByCategoryId: builder.query({
    //   query: (id) => {
    //     return {
    //       url: `/api/public/tradersByCategoryId/${id}`,
    //       method: "GET",
    //     };
    //   },
    //   transformResponse: (responseData) => {
    //     const { error, data } = responseData; // ToDo: error
    //     return data;

    //     // const loadedSubCategories = responseData.map((subCategory) => {
    //     //   return sub_category;
    //     // });
    //     // return apiQueryAdapter.setAll(initialState, loadedSubCategories);
    //   },
    //   invalidatesTags: [{ type: "TradersByCategory", id: "LIST" }],
    //   providesTags: ["TradersByCategory"],
    // }),
    fetchAllCategoriesWithSubcategories: builder.query({
      query: () => {
        return {
          url: "/api/public/fetchAllCategoriesWithSubcategories",
          meethod: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetTraderByIdMutation,
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesMutation,

  // useGetTradersByCategoryIdQuery,
  useFetchAllCategoriesWithSubcategoriesQuery,
} = globalApi;

// returns the query result object
export const selectCategoriesResult =
  globalApi.endpoints.getAllCategories.select();
export const selectSubCategoriesResult =
  globalApi.endpoints.getAllSubCategories.select();

// creates memoized selector
const selectApiQueriesData = createSelector(
  selectCategoriesResult,
  selectSubCategoriesResult,
  (apiQueryResult) => apiQueryResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAllCategory: selectAllCategories,
  selectSubCategoryById: selectSubCategoriesById,
  // Pass in a selector that returns the users slice of state
} = apiQueryAdapter.getSelectors(
  (state) => selectApiQueriesData(state) ?? initialState
);
