import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetweenTwo from "../../components/FlexBetweenTwo";
import {
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
} from "./adminApiSlice";
import { useFetchAllCategoriesWithSubcategoriesQuery } from "../../app/api/apiQuery";

const CategoryComponent = ({
  category,
  onClick,
  onUpdate,
  onCancel,
  onDelete,
}) => {
  const [editedCategoryName, setEditedCategoryName] = useState(category.name);
  const [isEditable, setIsEditable] = useState(false);

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    setIsEditable(false);
    onUpdate(category, editedCategoryName);
  };

  const handleCancelClick = () => {
    setIsEditable(false);
    setEditedCategoryName(category.name);
    onCancel(category);
  };

  const handleNameChange = (e) => {
    setEditedCategoryName(e.target.value);
  };

  const handleDeleteClick = () => {
    onDelete(category);
  };

  return (
    <Card>
      <FlexBetweenTwo>
        <CardContent onClick={() => onClick(category)}>
          {isEditable ? (
            <TextField
              value={editedCategoryName}
              onChange={handleNameChange}
              autoFocus
            />
          ) : (
            <Typography variant="body1">{category.name}</Typography>
          )}
        </CardContent>
        {isEditable ? (
          <>
            <IconButton onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </FlexBetweenTwo>
    </Card>
  );
};

const SubcategoryComponent = ({
  subcategory,
  onUpdate,
  onCancel,
  onDelete,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [editedSubcategoryName, setEditedSubcategoryName] = useState(
    subcategory.name
  );

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    onUpdate(subcategory, editedSubcategoryName);
    setIsEditable(false);
  };

  const handleCancelClick = () => {
    setEditedSubcategoryName(subcategory.name);
    setIsEditable(false);
    onCancel(subcategory);
  };

  const handleDeleteClick = () => {
    onDelete(subcategory);
  };

  const handleNameChange = (e) => {
    setEditedSubcategoryName(e.target.value);
  };

  return (
    <Card variant="outlined">
      <FlexBetweenTwo>
        <CardContent>
          {isEditable ? (
            <TextField
              value={editedSubcategoryName}
              onChange={handleNameChange}
              autoFocus
            />
          ) : (
            <Typography variant="body1">{subcategory.name}</Typography>
          )}
        </CardContent>
        {isEditable ? (
          <>
            <IconButton onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </FlexBetweenTwo>
    </Card>
  );
};

const DataManagement = () => {
  const { user_name, status, roles } = useAuth();

  const [addCategory, { data, isLoading, isSuccess, isError, error }] =
    useAddCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const [addSubCategory, { data: subCategoryData }] =
    useAddSubCategoryMutation();
  const [updateSubCategory] = useUpdateSubCategoryMutation();

  const [deleteSubCategory, { data: deleteSubCategoryDataResult }] =
    useDeleteSubCategoryMutation();

  const { data: csData } = useFetchAllCategoriesWithSubcategoriesQuery();
  const { code, status: csStatus, message, result: allCSData } = csData || {};

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryInput, setCategoryInput] = useState("");
  const [subcategoryInput, setSubcategoryInput] = useState("");
  const [showAddSubcategory, setShowAddSubcategory] = useState(false);
  const [categoryErrorMsg, setCategoryErrorMsg] = useState("");
  const [subCategoryErrorMsg, setSubCategoryErrorMsg] = useState("");

  useEffect(() => {
    if (status !== "Admin" || pathname !== "/secure/categories") {
      navigate("/");
    }
  }, [status, pathname, navigate]);
  useEffect(() => {
    if (allCSData) {
      setCategories([...allCSData]);
    }
  }, [allCSData]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setShowAddSubcategory(true);
    setSubcategoryInput("");
    setCategoryErrorMsg("");
  };

  const handleAddCategory = async () => {
    if (categoryInput.trim() === "") return;

    const { data: categoryData } = await addCategory({
      name: categoryInput,
    });
    const { code, status, message, result: allCategories } = categoryData || {};

    if (message) {
      // Handle error case
      setCategoryErrorMsg(message);
      setSubCategoryErrorMsg("");
    } else if (allCategories) {
      // Handle success case
      setCategories(allCategories);

      setCategoryErrorMsg("");
      setSubCategoryErrorMsg("");
    } else {
      // Handle other scenarios
      setCategoryErrorMsg(message);
      setSubCategoryErrorMsg("");
    }

    setCategoryInput("");
  };

  const handleAddSubcategory = async () => {
    if (subcategoryInput.trim() === "") return;

    try {
      const { data } = await addSubCategory({
        categoryId: selectedCategory?.id,
        subCategory: subcategoryInput,
      });
      const { code, status, message, result: allCategories } = data || {};

      setCategories([...allCategories]);

      setSelectedCategory((prevSelectedCategory) => {
        if (prevSelectedCategory.id === selectedCategory.id) {
          const updatedSubcategories = [
            ...prevSelectedCategory.subcategories,
            allCategories
              .find((category) => {
                return category.id === selectedCategory.id;
              })
              ?.subcategories.find(
                (subcategory) => subcategory.name === subcategoryInput
              ),
          ];
          return {
            ...prevSelectedCategory,
            subcategories: updatedSubcategories,
          };
        }
        return prevSelectedCategory;
      });

      setSubcategoryInput("");
      setSubCategoryErrorMsg(message);
    } catch (error) {
      setSubCategoryErrorMsg("Failed to add subcategory");
    }
  };

  const handleCategoryUpdate = async (category, editedName) => {
    if (category) {
      try {
        // const {
        //   error: { data: { message } = {} } = {},
        //   data: { allCategories } = [],
        // } = await updateCategory({
        //   categoryId: category?.category._id,
        //   newName: editedName,
        // });
        const { data } = await updateCategory({
          categoryId: category?.category.id,
          newName: editedName,
        });
        const { code, status, message, result: allCategories } = data || {};

        if (message) {
          // Handle error case
          setCategoryErrorMsg(message);
        } else if (allCategories) {
          setCategories(allCategories);
        }
      } catch (error) {
        setCategoryErrorMsg("Failed to update category");
      }
    }
  };

  const handleCategoryCancel = (category) => {
    // ToDo: Edit cancelled for category:", category);
  };

  const handleCategoryDelete = async (category) => {
    if (category) {
      try {
        // const {
        //   error: { data: { message } = {} } = {},
        //   data: { allCategories } = [],
        // } = await deleteCategory({
        //   categoryId: category?.category._id,
        // });
        const { data } = await deleteCategory({
          categoryId: category?.category.id,
        });
        const { code, status, message, result: allCategories } = data || {};

        if (message) {
          // Handle error case
          setCategoryErrorMsg(message);
        } else if (allCategories) {
          // Update the UI by removing the category and its subcategories from the state
          setCategories((prevCategories) => {
            return prevCategories.filter(
              (cat) => cat.category.id !== category.category_id
            );
          });

          setSelectedCategory(null);
        }
      } catch (error) {
        setCategoryErrorMsg("Failed to delete category");
      }
    }
  };

  const handleSubCategoryUpdate = async (subcategory, editedName) => {
    if (subcategory) {
      try {
        const {
          error: { data: { message } = {} } = {},
          data: { allCategories } = [],
        } = await updateSubCategory({
          subCategoryId: subcategory.id,
          newName: editedName,
        });

        if (message) {
          // Handle error case
          setSubCategoryErrorMsg(message);
        } else if (allCategories) {
          setCategories([...allCategories]);

          setSelectedCategory((prevSelectedCategory) => {
            if (
              prevSelectedCategory.category.id === selectedCategory.category.id
            ) {
              const updatedSubcategories =
                prevSelectedCategory.subcategories.map((sub) => {
                  if (sub.id === subcategory.id) {
                    return {
                      ...sub,
                      name: editedName,
                    };
                  }
                  return sub;
                });
              return {
                ...prevSelectedCategory,
                subcategories: updatedSubcategories,
              };
            }
            return prevSelectedCategory;
          });

          //   setSubcategoryInput("");
        }
      } catch (error) {
        setCategoryErrorMsg("Failed to update category");
      }
    }
  };

  const handleSubcategoryCancel = (subcategory) => {
    // ToDo: Canceling subcategory editing ", subcategory
  };

  const handleSubcategoryDelete = async (subcategory) => {
    try {
      // const {
      //   error: { data: { message } = {} } = {},
      //   data: { allCategories } = {},
      // } = await deleteSubCategory({ _id: subcategory._id });
      const { data } = await deleteSubCategory({ _id: subcategory.id });
      const { code, status, message, result: allCategories } = data || {};

      setCategories((prevCategories) => {
        return prevCategories.map((category) => {
          if (category.id === selectedCategory.id) {
            const updatedSubcategories = category.subcategories.filter(
              (subcat) => subcat.id !== subcategory.id
            );

            return {
              ...category,
              subcategories: updatedSubcategories,
            };
          }
          return category;
        });
      });

      setSelectedCategory((prevSelectedCategory) => {
        if (prevSelectedCategory.id === selectedCategory.id) {
          const updatedSubcategories =
            prevSelectedCategory.subcategories.filter(
              (subcat) => subcat.id !== subcategory.id
            );
          return {
            ...prevSelectedCategory,
            subcategories: updatedSubcategories,
          };
        }
        return prevSelectedCategory;
      });
    } catch (error) {
      // Handle error
      setSubCategoryErrorMsg(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper>
          <Typography variant="h6" align="center" sx={{ py: 2 }}>
            Data Management - Categories
          </Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={1}
            sx={{ p: 2 }}
          >
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <TextField
                  label="Category"
                  value={categoryInput}
                  onChange={(e) => setCategoryInput(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleAddCategory}
                  sx={{ mr: 1 }}
                >
                  Add
                </Button>
                {categoryErrorMsg}
              </Grid>
            </Grid>
            {categories.map((category, index) => (
              <Card
                key={index}
                variant="outlined"
                sx={{ cursor: "pointer", width: "100%", mt: 2 }}
              >
                <CategoryComponent
                  key={category.id}
                  category={category}
                  onClick={() => handleCategoryClick(category)}
                  onUpdate={handleCategoryUpdate}
                  onCancel={handleCategoryCancel}
                  onDelete={handleCategoryDelete}
                />
                {/* </FlexBetweenTwo> */}
              </Card>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <Typography variant="h6" align="center" sx={{ py: 2 }}>
            Sub Categories
          </Typography>
          <Grid
            container
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ p: 2 }}
          >
            {selectedCategory && showAddSubcategory && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1">
                  {selectedCategory.name}{" "}
                  {selectedCategory?.subcategories?.length ?? 0}
                </Typography>
                <Grid item container alignItems="center" spacing={1}>
                  <Grid item>
                    <TextField
                      label="Subcategory"
                      value={subcategoryInput}
                      onChange={(e) => setSubcategoryInput(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleAddSubcategory}
                      sx={{ mr: 1 }}
                    >
                      Add
                    </Button>
                    {subCategoryErrorMsg}
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedCategory &&
              selectedCategory?.subcategories?.map((subcategory, index) => {
                return subcategory ? (
                  <SubcategoryComponent
                    key={subcategory.id}
                    subcategory={subcategory}
                    onUpdate={handleSubCategoryUpdate}
                    onCancel={handleSubcategoryCancel}
                    onDelete={handleSubcategoryDelete}
                  />
                ) : null;
              })}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DataManagement;
