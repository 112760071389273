import React from "react";

import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const TraderStatus = ({ traders }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const columns = [
    {
      field: "status",
      headerName: "Status",
      flex: 0.3,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.5,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },
    // {
    //   field: "membership",
    //   headerName: "Membership",
    //   flex: 0.5,
    //   sortable: false,
    //   renderCell: (params) => params?.value?.type,
    //   width: 250,
    // },
    {
      field: "categories",
      headerName: "Categories",
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params?.value.map((cat) => `${cat?.category?.name} / `),
      width: 250,
    },
    {
      field: "contactInfo",
      headerName: "Contacts",
      flex: 1,
      sortable: false,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`
      renderCell: (params) =>
        `${params?.value?.companyName} / Tel: ${params?.value?.primaryNumber}`,
      width: 250,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 1,
    },
  ];
  const rowClickHandler = (record) => {
    navigate("trader-info", { state: record.row });
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        gridColumn="span 8"
        gridRow="span 3"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          height: 400,
          width: "100%",
          cursor: "pointer",
        }}
      >
        {traders && (
          <DataGrid
            // loading={isLoading}
            getRowId={(row) => row._id}
            rows={traders || []}
            columns={columns}
            autoHeight
            rowHeight={38}
            // pagination={true}
            onRowClick={rowClickHandler}
          />
        )}
      </Box>
    </Box>
  );
};

export default TraderStatus;
