import React from "react";
import useAuth from "../../hooks/useAuth";
import { useGetAllAdminsQuery } from "./adminApiSlice";

const AdminPage = () => {
  // const {
  //   data: admins,
  //   isSuccess,
  //   isError,
  //   isFetching,
  //   isLoading,
  // } = useGetAllAdminsQuery();
  const { data, isSuccess, isError, isFetching, isLoading } =
    useGetAllAdminsQuery();
  const { code, status, message, result: admins } = data || {};

  let content = <h1>Loading...</h1>;
  if (admins) {
    const { message, status, success } = admins;

    content = (
      <>
        <h1>How Many Admins: {message.length}</h1>
        <h1>Status: {status}</h1>
        <h1>Is Sucess: {success ? "Yes" : "No"}</h1>
        {message &&
          message.map((m) => (
            <div key={m._id}>
              <h2>user_name: {m.user_name}</h2>
              <h2>How many Roles: {m?.roles?.length}</h2>
              {m?.roles &&
                m?.roles?.map((mRoles) => (
                  <div key={(m._id + "_", mRoles)}>
                    <h3>Roles: {mRoles}</h3>
                  </div>
                ))}
            </div>
          ))}
      </>
    );
  }

  return content;
};

export default AdminPage;
