import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Layout from "./components/Layout";
import Public from "./components/Public";
import Login from "./features/auth/Login";
import AdminPage from "./features/admin/AdminPage";
import DataManagement from "./features/admin/DataManagement";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from "./config/roles";

import Dashboard from "./scenes/dashboard";
import TradersStatus from "./scenes/tradersStatus";
import SceneLayout from "./scenes/layout/SceneLayout";
import TraderInfo from "./features/traders/TraderInfo";

import SecureLayout from "./components/SecureLayout";
import HomeOwnersStatus from "./scenes/homeOwnersStatus";
import HomeOwnerInfo from "./features/homeOwners/HomeOwnerInfo";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route index element={<Public />} />
            <Route path="login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>
              <Route
                element={
                  <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
                }
              >
                <Route path="secure" element={<SecureLayout />}>
                  <Route element={<Prefetch />}>
                    <Route
                      element={<RequireAuth allowedRoles={[ROLES.Admin]} />}
                    >
                      <Route element={<SceneLayout />}>
                        <Route index path="dashboard" element={<Dashboard />} />
                        <Route path="traders" element={<TradersStatus />} />
                        <Route
                          path="traders/trader-info"
                          element={<TraderInfo />}
                        />

                        <Route
                          path="homeowners"
                          element={<HomeOwnersStatus />}
                        />
                        <Route
                          path="homeowners/home-owner-info"
                          element={<HomeOwnerInfo />}
                        />
                        <Route
                          path="homeownerstatus/home-owner-info"
                          element={<TraderInfo />}
                        />
                        <Route path="admin" element={<AdminPage />} />
                        <Route path="categories" element={<DataManagement />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<Public />} />
            {/* End Protected Routes */}
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
